<template>
    <layout class="wrapper">
        <!-- 面包屑 -->
        <div class="container bread-list mt15 flex x-left flex-wrap">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/'}">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/journal-article'}">期刊论文</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:''}">会议列表</router-link>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div v-if="subjectList.length" class="filter-wrapper mt10">
            <div class="filter-content pb5 container">
                <filter-tab title="学科" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
            </div>
        </div>

        <!-- 筛选条件 -->
        <div v-if="subjectIndex != -1" class="filter-condition active">
            <div class="container flex y-center x-left">
                <div class="search-text">搜索条件：</div>
                <a-breadcrumb class="search-breadcrumb">
                    <a-breadcrumb-item>{{ subjectList[subjectIndex].operate_name }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <a-button class="clear-btn" @click="handleClearEvent"
                    style="margin-left:20px;" size="small">清除</a-button>
            </div>
        </div>

        <div class="container meetpaper-list flex mt30">
            <div class="meeting-report">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="interview-list" v-if="meetPaperList.length">
                        <div v-for="(item,index) in meetPaperList" :key="index" class="hot-topic-box"
                            :class="{'mt20': index != 0}" @click="goPaperList(item.id)">
                            <across-media-item :item="item">
                                <template v-slot:bottom>
                                    <span class="color999" v-if='item.start_time'>{{item.start_time.slice(0,10)}}<span v-if="item.end_time">  至 {{item.end_time.slice(0,10)}}</span></span>
                                </template>
                            </across-media-item>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!meetPaperList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="approiCount > 0">
                    <a-pagination :total="approiCount" v-model:current="paperlistParams.page"
                        :page-size="paperlistParams.limit" show-quick-jumper @change="handlePageChangeEvent"
                        :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="meeting-right">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import filterTab from '@/components/filter-tab.vue';
import acrossMediaItem from '@/components/across-media-item';
import { jsonFormat } from '@/utils/jsonFormat';
import storage from 'store'
import { mapState } from 'vuex';
let layoutRightConfig = {
    // 本月会议
    meeting: {
        show: true, // 是否显示
        title: '本月会议', // 标题
        methods: 'get',
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 6,
            is_now_year_meeting: 1,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },
    // 推荐直播
    live: {
        show: true, // 是否显示
        methods: 'get',
        title: '推荐直播', // 标题
        data: {
            page: 1,
            limit: 3
        },   // 参数条件
    },
    // 推荐视频
    video: {
        show: true, // 是否显示
        methods: 'get',
        title: '最新回放', // 标题
        data: {
            page: 1,
            PageSize: 4
        },   // 参数条件
    },
}
export default {
    name: 'index',
    components: {
        layout,
        layoutRight,
        filterTab,
        acrossMediaItem
    },
    data() {
        return {
            paperlistParams: {
                subject: "",
                page: 1,
                limit: 10,
            },
            approiCount: 0,
            meetPaperList: [],
            subjectIndex: -1,
            subjectList: [],
            layoutRightConfig : {},
            loading: true,
        }
    },
    created() {
        this.getSubject()
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        // 清除筛选
        handleClearEvent(){
            this.handleFilterEvent('subjectIndex', -1);
        },

        // 期刊约稿列表
        getAbstractMeetingList() {
            this.loading = true
            this.request.post('AbstractMeeting', this.paperlistParams).then(res => {
                this.approiCount = parseInt(res.data.count)
                let paperObj = {
                    img:'pic_url',
                    title: 'cn_title',
                }
                this.meetPaperList = jsonFormat(res.data.list,paperObj)
                this.loading = false
            })
        },
        // 获取全部学科
        getSubject(){
            // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;
            this.$store.dispatch('getSubjectList').then(res => {
                let subjectList = [];
                res.data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })
                this.subjectList = subjectList;

                // 触发学科修改事件
                this.handleFilterEvent();
            })
        },
        // 学科切换
        handleFilterEvent(key,e){
            key && (this[key] = e);
            this.paperlistParams.page = 1;

            // 用户url学科或者已选学科
            let subject_ids = this.$route.query.subject_id;
            let operate_subject = subject_ids;

            // 切换后的学科
            if(this.subjectIndex != -1){
                subject_ids = this.subjectList[this.subjectIndex].subject_ids;
                operate_subject = this.subjectList[this.subjectIndex].id;
                this.paperlistParams.subject = subject_ids;
            }else{
                this.paperlistParams.subject = ''
            }
            
            // 获取列表数据
            this.getAbstractMeetingList();

            layoutRightConfig.meeting.data.subject_ids = subject_ids;
            layoutRightConfig.live.data.subject_ids = subject_ids;
            layoutRightConfig.video.data.subject_ids = subject_ids;
            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.paperlistParams.page = page
            this.getAbstractMeetingList();
        },
        // 会议相关论文列表
        goPaperList(id){
            this.$router.push({
                path: '/article-list',
                query: { id }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.filter-condition {
    background: #f2f4fa;
    overflow: hidden;
    height: 8px;

    &.active {
        height: 50px;
        line-height: 50px;
    }
}

.filter-wrapper {
    background: #ffffff;
    padding: 5px 0;
}

.meeting-report {
    width: calc(66.66% - 30px);

    .interview-list {
        min-height: 500px;
    }
}
.meeting-right {
    width: 33.33%;
}
@media screen and (max-width:768px){
    .container{
        padding: 0 10px;
    }
    .filter-wrapper{
        margin-top:0;
        position: sticky;
        top: 50px;
        z-index: 999;
        padding-bottom:0;
    }
     .filter-condition.active{
        height:40px;
        line-height:40px;
        .container{
            padding:0 10px;
            display: flex;
        }
    }
    .filter-content{
        width: 100%;
        margin-left: -14px;
        background: #fff;
    }
    .bread-list{
        display: none;
    }
    .meetpaper-list{
        display: block;
        .meeting-report{
            width: 100%;
            .interview-list{
                min-height: 0;
            }
        }
        .meeting-right{
            width: 100%;
            margin-top: 60px;
        }
    }
}
</style>
